import React, { FunctionComponent } from 'react';
import { Linking, View } from 'react-native';
import { useLayout } from '../context/LayoutContext';
import { Assets } from '../utils/Assets';
import { COLORS } from '../utils/Theme';
import { Button } from './Button';
import { CustomText } from './CustomText';

const useStyles = (): any => {
  const { WP } = useLayout();
  const width = WP();
  const height = width / 1.03;

  return {
    container: {
      width: '100%',
      height: '100%',
    },
    image: {
      width,
      height,
    },
    subTitle: {
      lineHeight: 25,
      letterSpacing: 0.2,
      marginTop: 20,
      marginBottom: 30,
    },
    content: {
      flex: 1,
      marginHorizontal: 25,
      marginBottom: 50,
      justifyContent: 'flex-end',
    },
  };
};

interface IDownloadProps {}

export const Download: FunctionComponent<IDownloadProps> = () => {
  const styles = useStyles();

  const onPress = () => {
    Linking.openURL('https://play.google.com/store/apps/details?id=com.fasky.numerology');
  };

  return (
    <View style={styles.container}>
      <img src={Assets.svgs.moon} style={styles.image} />
      <View style={styles.content}>
        <CustomText size={32} bold>
          Hello
        </CustomText>
        <CustomText color={COLORS.SECONDARY_TEXT} size={16} regular style={styles.subTitle}>
          Understand yourself and improve relationships with astrology.
        </CustomText>
        <Button title="CONTINUE" onPress={onPress} />
      </View>
    </View>
  );
};
