import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { useLayout } from '../context/LayoutContext';

const useStyles = (): any => {
  return {
    appContainer: {
      maxWidth: '428px',
      width: '100%',
      minHeight: '100%',
    },
  };
};

interface IAppContainerProps {}

export const AppContainer: FunctionComponent<IAppContainerProps> = (props) => {
  const styles = useStyles();

  const { setHeight, setWidth } = useLayout();

  const onLayout = (layout: any) => {
    setHeight(layout.nativeEvent.layout.height);
    setWidth(layout.nativeEvent.layout.width);
  };

  return (
    <View style={styles.appContainer} onLayout={onLayout}>
      {props.children}
    </View>
  );
};
