import React from 'react';
import { StyleSheet, View } from 'react-native';

import { LayoutProvider } from './src/context/LayoutContext';
import { AppContainer } from './src/components/AppContainer';
import { Download } from './src/components/Download';

export default function App() {
  return (
    <LayoutProvider>
      <View style={styles.container}>
        <AppContainer>
          <Download />
        </AppContainer>
      </View>
    </LayoutProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});
