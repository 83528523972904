import React, { FunctionComponent, useMemo } from 'react';
import { Animated, Text, TextProps } from 'react-native';
import { COLORS } from '../utils/Theme';

export interface ICustomTextProps extends TextProps {
  children?: any;
  italic?: boolean;
  size?: number;
  color?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  regular?: boolean;
  semibold?: boolean;
  bold?: boolean;
  ref?: any;
  animated?: boolean;
  noHTML?: boolean;
}

const getFontFamily = () => {
  return 'Inter';
};

const getFontWeight = (props: ICustomTextProps) => {
  if (props.regular) {
    return 400;
  } else if (props.semibold) {
    return 600;
  } else if (props.bold) {
    return 700;
  }

  return 400;
};

const useStyles = (props: ICustomTextProps): any => {
  const { size = 12, color = COLORS.WHITE, textAlign = 'left' } = props;

  return {
    text: {
      fontSize: size,
      color: color,
      textAlign: textAlign,
    },
  };
};

export const CustomText: FunctionComponent<ICustomTextProps> = React.forwardRef(
  (props: ICustomTextProps, ref: any) => {
    const { children, style, animated, noHTML, ...rest } = props;
    const styles = useStyles(props);

    const fontFamily = useMemo(() => {
      return getFontFamily();
    }, []);

    const fontWeight = useMemo(() => {
      return getFontWeight(props);
    }, [props.semibold, props.bold, props.regular]);

    return animated ? (
      <Animated.Text style={[styles.text, { fontFamily, fontWeight }, style]} {...rest} ref={ref}>
        <div dangerouslySetInnerHTML={{ __html: children }} />
      </Animated.Text>
    ) : (
      <Text style={[styles.text, { fontFamily, fontWeight }, style]} {...rest} ref={ref}>
        {noHTML ? children : <div dangerouslySetInnerHTML={{ __html: children }} />}
      </Text>
    );
  },
);

CustomText.defaultProps = {
  // size: CONTENT_FONT_SIZE,
} as Partial<ICustomTextProps>;
