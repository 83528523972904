import React, { FunctionComponent } from 'react';
import { TouchableOpacityProps, TouchableOpacity, View, ActivityIndicator } from 'react-native';

import { COLORS } from '../utils/Theme';
import { CustomText } from './CustomText';

const BUTTON_HEIGHT = 50;

export enum EBUTTONTYPE {
  PRIMARY = 'primary',
}

const useStyles = (props: IButtonProps): any => {
  const bHeight = props.buttonHeight ? props.buttonHeight : BUTTON_HEIGHT;

  return {
    [EBUTTONTYPE.PRIMARY]: {
      button: { backgroundColor: COLORS.BUTTON_PRIMARY },
      text: { color: COLORS.WHITE },
    },
    base: {
      button: {
        borderRadius: bHeight / 2,
        height: bHeight,
        justifyContent: 'center',
      },
      text: {
        fontSize: bHeight / 3,
        textAlign: 'center',
        letterSpacing: 0.4,
      },
    },
    disabled: {
      button: {
        backgroundColor: COLORS.BUTTON_DISABLED,
      },
      text: {
        color: COLORS.BUTTON_DISABLED_TEXT,
      },
    },
    childrenContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

export interface IButtonProps extends TouchableOpacityProps {
  type?: EBUTTONTYPE;
  title?: string;
  renderIcon?: any;
  buttonHeight?: any;
  loading?: boolean;
}

export const Button: FunctionComponent<IButtonProps> = (props) => {
  const styles = useStyles(props);
  const {
    title,
    type = EBUTTONTYPE.PRIMARY,
    disabled,
    loading,
    style,
    renderIcon,
    children,
    ...rest
  } = props;
  const renderContent = () => {
    if (loading) {
      return <ActivityIndicator color={styles[type].text.color} />;
    }

    return title ? (
      <>
        <View style={styles.childrenContainer}>
          {renderIcon && renderIcon()}
          <CustomText
            style={[styles.base.text, styles[type].text, disabled && styles.disabled.text]}
            semibold>
            {title}
          </CustomText>
        </View>
      </>
    ) : (
      children
    );
  };

  return (
    <TouchableOpacity {...rest} disabled={disabled}>
      <View
        style={[
          styles.base.button,
          styles[type].button,
          disabled && styles.disabled.button,
          style,
        ]}>
        {renderContent()}
      </View>
    </TouchableOpacity>
  );
};
