import React, { useContext, createContext, useState } from 'react';

interface LayoutContextType {
  width: number;
  setWidth: (WP: number) => void;
  height: number;
  setHeight: (HP: number) => void;
  WSPACING: (factor?: number) => number;
  HSPACING: (factor?: number) => number;
  WP: (factor?: number) => number;
  HP: (factor?: number) => number;
}

const LayoutContext = createContext<LayoutContextType>({} as LayoutContextType);

export const LayoutProvider = (props: any) => {
  const value = useLayoutProvider();
  return <LayoutContext.Provider value={value}>{props.children}</LayoutContext.Provider>;
};

export const useLayout = () => {
  return useContext(LayoutContext);
};

const useLayoutProvider = (): LayoutContextType => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const WP = (value: number = 100) => {
    if (value === 0) {
      return 0;
    }

    return width * (value / 100);
  };

  const HP = (value: number = 100) => {
    if (value === 0) {
      return 0;
    }

    return height * (value / 100);
  };

  const WSPACING = (factor: number = 1) => WP(2.5) * factor;
  const HSPACING = (factor: number = 1) => HP(3) * factor;

  return {
    height,
    setHeight,
    width,
    setWidth,
    WSPACING,
    HSPACING,
    HP,
    WP,
  };
};
